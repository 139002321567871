import React from 'react';
import { connect } from 'react-redux';
import DNCButton from '../components/DNCButton';
import { Descriptions, Button, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import i18next from 'i18next';


class ButtonGoogleSearch extends React.Component {
  render() {
    let displayButton = true;
    if (!this.props.content || this.props.content === "None") {
      displayButton = false
    }
    let url_encode_content = encodeURI(this.props.content);
    let google_search_url = "https://www.google.com/search?q=" + url_encode_content;
    return (
      <span>
        {
          displayButton
            ?
            < Tooltip title={i18next.t("Search on Google")} >
              <Button type="link" icon={<SearchOutlined />} style={{ "color": "#d7d7db" }} target="_blank" href={google_search_url} />
            </Tooltip >
            :
            <span></span>
        }
      </span>
    );
  }
}

class ContactDetails extends React.Component {
  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  render() {
    // Clean up the contact_fields
    // let contact_fields = { ...this.props.contact_data.contact_fields }
    let contact_fields = JSON.parse(JSON.stringify(this.props.contact_data.contact_fields));

    console.log(">> ContactDetails - subscriber_id: ", contact_fields['subscriber_id']);

    delete contact_fields['campaign_id'];
    delete contact_fields['contact_id'];
    delete contact_fields['subscriber_id'];
    delete contact_fields['internal_notes'];
    delete contact_fields['disposition'];
    delete contact_fields['tz_code'];
    delete contact_fields['gmt_offset'];
    delete contact_fields['count_attempt'];
    delete contact_fields['created_date'];

    let last_attempt = new Date(contact_fields['last_attempt']);
    contact_fields['last_called'] = moment(last_attempt).format('lll');
    delete contact_fields['last_attempt'];

    let display_fields = this.props.contact_data.callcenter_config.display_fields;
    console.log(display_fields);
    // let display_fields = 'phone_number|contact number|searchable,last_called|hidden,last_name,first_name,address||searchable,city,property_value';
    // let display_fields = '';
    if (!display_fields) {
      display_fields = '';
    }
    display_fields = display_fields.replace(/;/g, ",")

    if (display_fields.length === 0) {
      display_fields = Object.keys(contact_fields).join(",");
    }
    console.log("contact_fields: ", contact_fields);
    console.log("display_fields: ", display_fields);

    let ordered_field = {};
    let fields_array = display_fields.split(",")
    fields_array.map(field => {
      let arr_field = field.split('|');
      let field_name = arr_field[0]
      field_name = field_name ? field_name.trim() : field_name;
      // custom name
      let custom_name = arr_field[0]; // default
      if (arr_field[1]) {
        custom_name = arr_field[1]
      }
      custom_name = custom_name ? custom_name.trim() : custom_name;
      // Property
      let property = arr_field[2]
      property = property ? property.trim() : property;
      // If searchable
      let searchable = (property === 'searchable');
      if (field_name === 'address') {
        searchable = true;
      }

      if (property !== 'hidden') {
        ordered_field[field_name] = {
          "name": custom_name,
          "value": contact_fields[field_name],
          "searchable": searchable
        }
      }
      return true;
    });

    console.log(ordered_field);
    const keys = Object.keys(ordered_field);
    let counter = 0;
    return (
      <div>
        <Descriptions
          title={
            <div>
              {i18next.t("Contact Info")}
              &nbsp; &nbsp;
              <DNCButton subscriber_id={contact_fields['subscriber_id']} />
            </div>
          }
          bordered layout="vertical"
          column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        >

          {
            keys.map(item => {
              let label = this.Capitalize(ordered_field[item].name).replace(/_/g, " ");
              counter = counter + 1;
              return (
                <Descriptions.Item label={label} key={counter}>
                  {ordered_field[item].value}
                  {
                    ordered_field[item].searchable &&
                    <ButtonGoogleSearch content={ordered_field[item].value} />
                  }
                </Descriptions.Item>
              );
            })
          }
        </Descriptions>
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    contact_data: state.contact.contact_data
  }
}

export default connect(mapStateToProps)(ContactDetails);
